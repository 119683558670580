import React from "react";
import * as Styles from "./styles";

export const Switch = (props) => {
  const labelText = props.checked ? "On" : "Off";

  return (
    <Styles.Wrapper className={props.className}>
      <Styles.Label htmlFor={props.name} checked={props.checked}>
        {labelText}
      </Styles.Label>
      <Styles.InputLabel>
        <Styles.Input
          id={props.name}
          name={props.name}
          type="checkbox"
          {...props}
        />
        <Styles.Slider className="slider" />
      </Styles.InputLabel>
    </Styles.Wrapper>
  );
};
