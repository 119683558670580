import css from "@styled-system/css";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;

  ${css({
    flexDirection: ["column", "row"],
  })}
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ contentRightMobileFirst }) =>
    css({
      order: contentRightMobileFirst ? ["2", "0"] : "0",
    })}
`;

export const RightColumn = styled(LeftColumn)`
  ${({ contentRightMobileFirst }) =>
    css({
      order: contentRightMobileFirst ? ["0", "0"] : "0",
    })}
`;

export const SpacerWrapper = styled.div`
  ${({ contentRightMobileFirst }) =>
    css({
      order: contentRightMobileFirst ? ["1", "0"] : "0",
    })}
`;
