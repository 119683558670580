import React from "react";
import * as Styles from "./styles";

export const Row = ({ children }) => {
  return (
    <Styles.Wrapper>
      <Styles.Container>{children}</Styles.Container>
    </Styles.Wrapper>
  );
};
