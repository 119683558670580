import React from "react";
import * as Styles from "./styles";
import { Spacer } from "../spacer";

export const ContentLeftRight = ({
  contentLeft,
  contentRight,
  contentRightMobileFirst,
}) => {
  return (
    <Styles.Wrapper>
      {contentLeft && (
        <Styles.LeftColumn contentRightMobileFirst={contentRightMobileFirst}>
          {contentLeft}
        </Styles.LeftColumn>
      )}
      <Styles.SpacerWrapper contentRightMobileFirst={contentRightMobileFirst}>
        <Spacer height={3} width={3} />
      </Styles.SpacerWrapper>
      {contentRight && (
        <Styles.RightColumn contentRightMobileFirst={contentRightMobileFirst}>
          {contentRight}
        </Styles.RightColumn>
      )}
    </Styles.Wrapper>
  );
};
