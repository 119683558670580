import css from "@styled-system/css";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  ${css({
    marginBottom: [2, 3],
  })}
`;

export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
`;
