import styled from "styled-components";
import css from "@styled-system/css";

export const Wrapper = styled.div`
  align-items: center;
  display: inline-flex;
`;

export const InputLabel = styled.label`
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
`;

export const Input = styled.input`
  height: 0;
  opacity: 0;
  width: 0;

  &:checked + .slider {
    ${css({
      background: "background.tertiary",
    })}
  }

  &:checked + .slider:before {
    transform: translateX(26px);
  }
`;

export const Slider = styled.span`
  border-radius: 50px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 250ms ease;

  ${css({
    boxShadow: "dark",
  })}

  &:before {
    border-radius: 50px;
    bottom: 4px;
    content: "";
    height: 26px;
    left: 4px;
    position: absolute;
    transition: all 250ms ease;
    width: 26px;

    ${css({
      backgroundColor: "foreground.link",
    })}
  }
`;

export const Label = styled.label`
  margin: 0 10px;
  width: 25px;

  ${({ checked }) =>
    css({
      color: checked ? "foreground.link" : "foreground.primary",
      fontWeight: "bold",
    })}
`;
