import React, { useState, useEffect } from "react";
import { getControlCookie, setControlCookie } from "../services/cookies";
import { Box } from "../components/box";
import { ContentLeftRight } from "../components/content-left-right";
import { Hero } from "../components/hero";
import { Layout } from "../components/layout";
import { Row } from "../components/row";
import { Seo } from "../components/seo";
import { Switch } from "../components/switch";
import { Text } from "../components/text";
import HeroImage from "../images/pages/about-us/dog-vaccination.jpg";
import SEOImage from "../images/wvs-india-seo.jpg";

export default function Cookies() {
  const [cookiesEnabled, setCookiesEnabled] = useState(false);

  useEffect(() => {
    const cookiesValue = getControlCookie().cookies;
    setCookiesEnabled(cookiesValue);
  }, []);

  const handleChange = (event) => {
    const controlCookie = getControlCookie();
    setControlCookie({
      ...controlCookie,
      cookies: event.target.checked,
      cookieBar: false,
    });

    setCookiesEnabled(event.target.checked);
  };

  return (
    <Layout>
      <Seo lang="en" title="Cookies" image={SEOImage} />
      <Hero image={HeroImage} />
      <Row>
        <Box border>
          <ContentLeftRight
            contentLeft={
              <Text>
                Cookies are small text files that are placed on your computer by
                websites that you visit. They are widely used in order to make
                websites work, or work more efficiently, as well as to provide
                information to the owners of the site. The table below explains
                the cookies we use and why.
              </Text>
            }
            contentRight={
              <Switch
                checked={cookiesEnabled}
                onChange={handleChange}
                name="cookie-control"
              />
            }
          />
        </Box>
      </Row>
      <Row>
        <div className="table-wrapper">
          <table cellPadding="0" cellSpacing="0">
            <thead>
              <tr>
                <th>Cookie</th>
                <th>Name</th>
                <th>Purpose</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Text>Cookie preference</Text>
                </td>
                <td>
                  <Text>cookie-control</Text>
                </td>
                <td>
                  <Text>
                    This cookie is used to remember a user's choice about
                    cookies on{" "}
                    <a href="/" title="wvsindia.org">
                      wvsindia.org
                    </a>
                    . Where users have previously indicated a preference, that
                    user's preference will be stored in this cookie.
                  </Text>
                </td>
              </tr>
              <tr>
                <td>Universal Analytics (Google)</td>
                <td>
                  _ga
                  <br />
                  _gali
                  <br />
                  _gat
                  <br />
                  _gid
                  <br />
                  ad_user_data
                  <br />
                  ad_personalization
                  <br />
                  ad_storage
                  <br />
                  analytics_storage
                </td>
                <td>
                  <p>
                    These cookies are used to collect information about how
                    visitors use our website. We use the information to compile
                    reports and to help us improve the website. The cookies
                    collect information in an anonymous form, including the
                    number of visitors to the website and blog, where visitors
                    have come to the website from and the pages they visited.
                  </p>
                  <p>
                    <a
                      href="https://support.google.com/analytics/answer/6004245"
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Read Google's overview of privacy and safeguarding data"
                    >
                      Read Google's overview of privacy and safeguarding data
                    </a>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Text>
          <strong>How do I change my cookie settings?</strong>
        </Text>
        <Text>
          You can change your cookie preferences at any time by clicking on the
          cookies link in the footer. You can then adjust the cookie slider to
          'On' or 'Off'. You may need to refresh your page for your settings to
          take effect.
        </Text>
        <Text>
          Alternatively, most web browsers allow some control of most cookies
          through the browser settings. To find out more about cookies,
          including how to see what cookies have been set, visit{" "}
          <a
            href="https://www.aboutcookies.org/"
            rel="noopener noreferrer"
            target="_blank"
            title="www.aboutcookies.org"
          >
            www.aboutcookies.org
          </a>{" "}
          or{" "}
          <a
            href="https://www.allaboutcookies.org/"
            rel="noopener noreferrer"
            target="_blank"
            title="www.allaboutcookies.org"
          >
            www.allaboutcookies.org
          </a>
          .
        </Text>
        <Text>Find out how to manage cookies on popular browsers:</Text>
        <ul className="ml-30">
          <li>
            <a
              href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en"
              rel="noopener noreferrer"
              target="_blank"
              title="Google Chrome"
            >
              Google Chrome
            </a>
          </li>
          <li>
            <a
              href="https://support.microsoft.com/en-gb/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy"
              rel="noopener noreferrer"
              target="_blank"
              title="Microsoft Edge"
            >
              Microsoft Edge
            </a>
          </li>
          <li>
            <a
              href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
              rel="noopener noreferrer"
              target="_blank"
              title="Mozilla Firefox"
            >
              Mozilla Firefox
            </a>
          </li>
          <li>
            <a
              href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
              rel="noopener noreferrer"
              target="_blank"
              title="Microsoft Internet Explorer"
            >
              Microsoft Internet Explorer
            </a>
          </li>
          <li>
            <a
              href="https://help.opera.com/en/latest/"
              rel="noopener noreferrer"
              target="_blank"
              title="Opera"
            >
              Opera
            </a>
          </li>
          <li>
            <a
              href="https://support.apple.com/en-gb/safari"
              rel="noopener noreferrer"
              target="_blank"
              title="Apple Safari"
            >
              Apple Safari
            </a>
          </li>
        </ul>
        <Text>
          To find information relating to other browsers, visit the browser
          developer's website.
        </Text>
      </Row>
    </Layout>
  );
}
